const moment = require('moment');

const join = (rules: Function[]) => (value: any, data: any) =>
    rules.map((rule) => rule(value, data)).filter((error) => !!error)[0 /* first error */];

export const isEmpty = (value?: any) => value === undefined || value === null || (typeof value === 'string' && value.trim() === '');

export function isNotBeforeToday(value?: string) {
    if (value && moment(value).isBefore(moment(), 'day')) {
        return `Date cannot be in the past`;
    }
}

export function isNotAfterToday(value?: string) {
    if (value && moment(value).isAfter(moment(), 'day')) {
        return `Date cannot be in the future`;
    }
}

export function isNotUnderAge(value?: string) {
    if (value && moment(value).isAfter(moment().subtract(16, 'years'), 'day')) {
        return `You must be 16 or older`;
    }
}

export function email(value?: string) {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)) {
        return 'Invalid email address';
    }
}

export function phone(value?: string) {
    if (
        value &&
        (value.length < 5 || !/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3,5}\)?[\s.-]?\d{3,4}[\s.-]?\d{2,4}$/.test(value))
    ) {
        return 'Invalid phone number';
    }
}

export function required(value?: string) {
    if (isEmpty(value)) {
        return 'Required';
    }
}

export function minLength(min: number) {
    return (value?: string) => {
        if (!isEmpty(value) && value && value.length < min) {
            return `Must be at least ${min} characters`;
        }
    };
}

export function maxLength(max: number) {
    return (value?: string) => {
        if (!isEmpty(value) && value && value.length > max) {
            return `Must be no more than ${max} characters`;
        }
    };
}

export function minSize(min: number) {
    return (value?: any) => {
        if (!isEmpty(value) && Number(value) < min) {
            return `Can not be less than ${min}`;
        }
    };
}

export function maxSize(max: number) {
    return (value?: any) => {
        if (!isEmpty(value) && Number(value) > max) {
            return `Can not be more than ${max}`;
        }
    };
}

export function integer(value?: any) {
    if (value && !Number.isInteger(Number(value))) {
        return 'Must be a number';
    }
}

export function oneOf(enumeration: string[]) {
    return (value: string) => {
        if (!~enumeration.indexOf(value)) {
            return `Must be one of: ${enumeration.join(', ')}`;
        }
    };
}

export function match(field: string) {
    return (value: any, data: { [key: string]: any }) => {
        if (data) {
            if (value !== data[field]) {
                return 'Do not match';
            }
        }
    };
}

export function createValidator(rules: any) {
    return (data: { [key: string]: any } = {}) => {
        let errors: any = {};
        Object.keys(rules).forEach((key) => {
            if (Array.isArray(rules[key])) {
                // @ts-ignore
                const rule = join([].concat(rules[key])); //  concat enables both functions and arrays of functions
                const error = rule(data[key], data);
                if (error) {
                    errors[key] = error;
                }
            } else if (typeof rules[key] === 'object') {
                // handle nested objects in form
                errors = { ...errors, [key]: createValidator(rules[key])(data[key]) };
            }
        });
        return errors;
    };
}
